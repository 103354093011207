import { rapidReady } from '../../../../lib/utils/rapid'

const { debounce } = window.wafer.utils
const FORM_SELECTOR = '.market-form-wrapper form'
const NEW_FORM_SELECTOR = '.market-search-box form'
const FORM_A_SELECTOR = '.search-wrapper'
const RESET_FORM_SELECTOR = '.close-wrapper'
const INDICES_CLASS = 'indices'
const HIDE_BTN_CLASS = 'btn--hidden'
const SLIDES_CONTAINER_SELECTOR = '.slides'
const SLIDES_INLINE_CLASS = 'slides--inline'
const DETAILS_SELECTOR = '.details .tab'
const SCROLLVIEW_CLASS = 'wafer-scrollview'
const NEXT_BTN_SELECTOR = '.next-btn'
const PREV_BTN_SELECTOR = '.prev-btn'
const BTN_SELECTORS = `${PREV_BTN_SELECTOR}, ${NEXT_BTN_SELECTOR}`
const INNER_TAB_SELECTOR = '.inner-tab'
const WAFER_STATE_KEY = 'yFinanceAutoComplete'
const TABS_CLASS = '.tabs'
const MY_PORTFOLIO = '.my-portfolio'
const DE_REMOVE = `${FORM_SELECTOR}, ${TABS_CLASS}, ${MY_PORTFOLIO}`
const MARKETS_AUTOCOMPLETE = '#markets-autocomplete'
const RESET_BUTTON = '#removeCompany'
const SEARCH_BUTTON = '#searchCompanyTicker'
const DROP_DOWN_SUGGESTION_CLASS = 'drop-down-suggestion'
const QUOTE_LOOKUP_SELECTOR = '.wafer-autocomplete'
const OPTION_SELECTOR = '.wafer-autocomplete-item'
const MARKET_SUGGESTION_CONTAINER = '#markets-suggestion-box'
const MARKET_TAB_SLIDE_CLASS_V2 = '.finance-market__tabs-section .slides'

export default (() => {
  class YFinanceMarkets {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }

      window.wafer.ready(() => {
        this.elems.forEach(elem => this.init(elem))
        window.addEventListener('resize', debounce(this.resizeAndInitializeCarousel, 200, this))
        this.resizeAndInitializeCarousel()
      })
    }

    init(elem) {
      const form = elem.querySelector(FORM_SELECTOR) || elem.querySelector(NEW_FORM_SELECTOR)
      const formBtn = elem.querySelector(FORM_A_SELECTOR)
      const resetBtn = elem.querySelector(RESET_FORM_SELECTOR)
      this.marketSuggestionContainer = elem.querySelector(MARKET_SUGGESTION_CONTAINER)

      wafer.base.addObserver(WAFER_STATE_KEY, () => {
        this.dispatchSubmit(null, form)
      })

      const isDe = elem?.dataset.domain === 'de'
      if (isDe) {
        // Styling for DE
        this.removeTabs()
      }

      if (form) {
        form.addEventListener('submit', e => {
          e.preventDefault()
          e.stopPropagation()
          this.submitHandler(e)
          this.marketSuggestionHandler(form, elem)
        })
        formBtn.addEventListener('click', e => {
          e.preventDefault()
          e.stopPropagation()
          this.emitRapidBeacon('searchquotes', 'icon', form)
          this.dispatchSubmit(e, form)
        })
        resetBtn.addEventListener('click', e => {
          this.resetFormHandler(e, form)
        })
      }
      this.dropDownSuggestion(elem)
    }

    marketSuggestionHandler(form, elem) {
      form.p.value = ''
      this.buttonOptionToggle(elem, 'search')
    }

    buttonOptionToggle(elem, option = 'search') {
      if (option === 'search') {
        elem.querySelector(RESET_BUTTON).style.display = 'none'
        elem.querySelector(SEARCH_BUTTON).style.display = 'block'
      } else {
        elem.querySelector(RESET_BUTTON).style.display = 'inline-block'
        elem.querySelector(SEARCH_BUTTON).style.display = 'none'
      }
    }

    dropDownSuggestion(elem) {
      const self = this
      if (elem) {
        const autoComplete = elem.querySelector(MARKETS_AUTOCOMPLETE)
        autoComplete.addEventListener('keyup', e => {
          const quoteLookupElem = e.currentTarget
          if (quoteLookupElem.value) {
            quoteLookupElem.classList.add(DROP_DOWN_SUGGESTION_CLASS)
            self.buttonOptionToggle(elem, 'close')
          } else {
            quoteLookupElem.classList.remove(DROP_DOWN_SUGGESTION_CLASS)
            self.buttonOptionToggle(elem, 'search')
          }
        })

        // click outside the suggestion box
        window.onclick = function(e) {
          if (autoComplete.value) {
            self.buttonOptionToggle(elem, 'close')
          } else {
            self.buttonOptionToggle(elem, 'search')
          }
          if (!e.target.matches(QUOTE_LOOKUP_SELECTOR)) {
            autoComplete.classList.remove(DROP_DOWN_SUGGESTION_CLASS)
          }
        }

        autoComplete.addEventListener('focus', e => {
          const quoteLookupElem = e.currentTarget
          setTimeout(() => {
            const isDropDownActive = !!elem.querySelector(OPTION_SELECTOR)
            if (isDropDownActive) {
              quoteLookupElem.classList.add(DROP_DOWN_SUGGESTION_CLASS)
            } else {
              quoteLookupElem.classList.remove(DROP_DOWN_SUGGESTION_CLASS)
            }
          }, 500)
        })
      }
    }

    removeTabs() {
      document.querySelectorAll(DE_REMOVE).forEach(e => e.parentNode.removeChild(e))
      document.querySelector(MARKET_TAB_SLIDE_CLASS_V2).classList.add('noTabs')
    }

    emitRapidBeacon(slk, elmt, form) {
      rapidReady(rapid => {
        const rapidPos = form && form.p && form.p.dataset.rapid_p
        const overridesValue = form && form.p && form.p.dataset.overrideYlk
        let overrides = {}
        if (overridesValue) {
          try {
            overrides = JSON.parse(overridesValue)
          } catch (e) {
            overrides = {}
          }
        }
        rapid.beaconClick(
          overrides.ll2 === 'homepage' ? 'content-block' : 'releases',
          slk,
          rapidPos,
          {
            subsec: 'yfinance_markets',
            itc: 0,
            elmt,
            elm: 'search',
            ll2: window.rapidConfig?.keys?.site || 'aol',
            ...overrides
          },
          'Interaction'
        )
      })
    }

    resizeAndInitializeCarousel(e) {
      const winWidth = window.innerWidth
      if (winWidth > 900) {
        this.elems.forEach(elem => {
          if (elem.classList.contains(INDICES_CLASS)) {
            this.disableScrollView(elem)
          }
        })
      } else {
        this.elems.forEach(elem => {
          if (elem.classList.contains(INDICES_CLASS)) {
            this.enableScrollView(elem)
          }
        })
      }
    }

    enableScrollView(elem) {
      this.showBtns(elem)
      this.showDetailsInline(elem)
      const detailsTab = [...elem.querySelectorAll(DETAILS_SELECTOR)]
      detailsTab.forEach(details => {
        if (!details.classList.contains(SCROLLVIEW_CLASS)) {
          details.classList.add(SCROLLVIEW_CLASS)
        }
      })
      window.wafer.base.sync(document.body)
    }

    disableScrollView(elem) {
      this.hideBtns(elem)
      this.showDetailsBlock(elem)
      const detailsTab = [...elem.querySelectorAll(DETAILS_SELECTOR)]
      detailsTab.forEach(details => {
        const slideContainer = details.querySelector(SLIDES_CONTAINER_SELECTOR)
        slideContainer.removeAttribute('style')
        const innerTabs = [...slideContainer.querySelectorAll(INNER_TAB_SELECTOR)]
        innerTabs.forEach(tab => {
          tab.removeAttribute('style')
        })
        window.wafer.base.destroy(details)
      })
    }

    showBtns(elem) {
      const btns = [...elem.querySelectorAll(BTN_SELECTORS)]
      btns.forEach(btn => {
        if (btn.classList.contains(HIDE_BTN_CLASS)) {
          btn.classList.remove(HIDE_BTN_CLASS)
        }
      })
    }

    hideBtns(elem) {
      const btns = [...elem.querySelectorAll(BTN_SELECTORS)]
      btns.forEach(btn => {
        if (!btn.classList.contains(HIDE_BTN_CLASS)) {
          btn.classList.add(HIDE_BTN_CLASS)
        }
      })
    }

    showDetailsInline(elem) {
      const slides = [...elem.querySelectorAll(SLIDES_CONTAINER_SELECTOR)]
      slides.forEach(slide => {
        if (!slide.classList.contains(SLIDES_INLINE_CLASS)) {
          slide.classList.add(SLIDES_INLINE_CLASS)
        }
      })
    }

    showDetailsBlock(elem) {
      const slides = [...elem.querySelectorAll(SLIDES_CONTAINER_SELECTOR)]
      slides.forEach(slide => {
        if (slide.classList.contains(SLIDES_INLINE_CLASS)) {
          slide.classList.remove(SLIDES_INLINE_CLASS)
        }
      })
    }

    resetFormHandler(e, form) {
      e.preventDefault()
      form.reset()
    }

    submitHandler(e) {
      // This is required because yahoo finance need the search value to be both a path and query param.
      // If it does not have both it will not redirect to the lookup path for unknown symbols.
      e.preventDefault()
      e.stopPropagation()
      const form = e.target
      const input = form.p
      const { value } = input
      const actionUrl = form.action
      const reqUrl = `${actionUrl}${value}?p=${value}`
      const w = window.open()
      w.opener = null
      w.document.location.replace(reqUrl)
    }

    dispatchSubmit(e, form) {
      const submit = document.createEvent('HTMLEvents')
      submit.initEvent('submit', true, true)
      form.dispatchEvent(submit)
    }
  }

  return YFinanceMarkets
})()
